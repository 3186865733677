/* eslint-disable react/destructuring-assignment */
import React from 'react';
import FilterableTable from 'react-filterable-table';
import { Link, navigate } from 'gatsby';
import slugify from 'slugify';

import {
  Button,
  Card,
  Checkbox,
  InputGroup,
  Intent,
} from '@blueprintjs/core';

import styles from './ZonesTable.module.scss';

const customSlugify = string => slugify(string, {
  remove: /[*+~./()'"!:@]/g, // regex to remove characters
  lower: true, // result in lower case
});

const preRender = typeof window === 'undefined';

const renderTitle = ({ value, record }) => (
  <>
    <Link to={`/${record.slug}${'.html'}`}>{value}</Link>
    {record.voc_dom && <><br /><span style={{ fontStyle: 'italic', fontSize: '.8em' }}>Activités: {record.voc_dom}</span></>}
  </>
);

// Stub localStorage for pre-rendering
if (preRender && global) {
  global.localStorage = { getItem () {}, setItem () {} };
}

const ZonesTable = props => {
  const [zones, setZones] = React.useState(props.zones);
  const [checked, setChecked] = React.useState(
    props.zones.reduce((acc, curr) => ({ ...acc, [curr.id]: false }), {}),
  );

  const filterZones = ({ target: { value } }) => {
    const filter = customSlugify(value);
    const newZones = props.zones.filter(zone => (
      customSlugify(zone.title).match(filter)
      || customSlugify(zone.ville).match(filter)
      || customSlugify(zone.departement).match(filter)
    ));
    setZones(newZones);
  };

  const renderLineCheckbox = renderProps => {
    const checkedIds = Object.keys(checked).filter(id => checked[id]);

    return (
      <input
        type="checkbox"
        checked={checked[renderProps.record.id]}
        onChange={() => {
          const isChecked = checked[renderProps.record.id];
          if (checkedIds.length < 3 || isChecked) {
            setChecked(prevChecked => ({
              ...prevChecked,
              [renderProps.record.id]: !prevChecked[renderProps.record.id],
            }));
          }
        }}
      />
    );
  };

  const { zones: allZones } = props;
  const checkedIds = Object.keys(checked).filter(id => checked[id]);
  const checkedData = checkedIds.map(id => allZones.find(zone => zone.id === id));

  return (
    <>
      <InputGroup
        onChange={filterZones}
        style={{ marginBottom: 10 }}
        placeholder="Rechercher une zone…"
      />

      {checkedIds.length > 0 && (
        <Card style={{ marginBottom: 10 }}>
          <h4 style={{ marginTop: 0 }}>Sélection</h4>
          {checkedData.map(zone => (
            <Checkbox
              key={zone.id}
              checked
              onChange={() => {
                setChecked(prevChecked => ({
                  ...prevChecked,
                  [zone.id]: !prevChecked[zone.id],
                }));
              }}
            >
              {zone.nom_ppal}
            </Checkbox>
          ))}

          <Button
            onClick={() => navigate(`/comparer?eae=${checkedIds.join(',')}`)}
            disabled={checkedIds.length < 2}
            intent={Intent.PRIMARY}
          >
            Comparer
          </Button>
        </Card>
      )}

      <FilterableTable
        data={zones}
        fields={[
          {
            name: 'compare',
            displayName: '',
            tdClassName: styles.compareBox,
            render: renderLineCheckbox,
          },
          {
            name: 'ville',
            displayName: <span>Commune</span>,
            inputFilterable: true,
            sortable: true,
          },
          {
            name: 'departement',
            displayName: <span><abbr title="Département">Dpt.</abbr></span>,
            inputFilterable: true,
            sortable: true,
          },
          {
            name: 'title',
            displayName: <span>Nom de la zone</span>,
            inputFilterable: true,
            sortable: true,
            render: renderTitle,
          },
          {
            name: 'nb_etablissement',
            displayName: <span><abbr title="Nombre d'établissements">Nb d'établissements</abbr></span>,
            sortable: true,
          },
        ]}
        pageSize={20}
        pagerTitles={{
          first: 'Première',
          last: 'Dernière',
        }}
        className={styles.main}
        tableClassName="bp3-html-table bp3-html-table-bordered bp3-html-table-condensed bp3-html-table-striped"
        style={{ width: '100%' }}
        initialSort="ville"
        recordCountName="espace d'activités"
        recordCountNamePlural="espaces d'activités"
        {...(preRender ? {
          pagersVisible: false,
        } : {})}
      />
    </>
  );
};

export default ZonesTable;
