import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../components/layout';

import ZonesTable from '../components/ZonesTable';
import { useZones } from '../hooks/useZones';

const topBarProps = { pageTitle: "Lister les espaces d'activités" };

const ZonesPage = () => {
  const zones = useZones();

  return (
    <Layout topBarProps={topBarProps}>
      <Helmet title="Espaces d'activités" />
      <br />

      <ZonesTable zones={zones} />
    </Layout>
  );
};

export default ZonesPage;
