import { graphql, useStaticQuery } from 'gatsby';

export const useZones = () => {
  const { wrapper } = useStaticQuery(graphql`
    query {
      wrapper: allZone {
        nodes {
          fields {
            slug
            title
            departement
          }
          id
          id_eae
          nom_ppal
          voc_dom
          ville: nom_comm
          voc_decl
          nb_etablissement
        }
      }
    }
  `);

  return wrapper.nodes.map(({ fields = {}, ...node }) => ({
    ...node,
    ...fields,
  }));
};

export default useZones;
